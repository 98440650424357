import moment from "moment";
import { axiosInstance } from "../config/axios";
import CalculateRoomDto from "../libs/enum/rooms";
import { RoomType } from "../libs/hotel";

export const loginWithEmailPassword = async (data: {
  email: string;
  password: string;
}) => {
  const response = await axiosInstance.post(
    `/guest/auth/login-with-email`,
    data
  );
  return response.data;
};

export const resetPassword = async (email: string) => {
  const response = await axiosInstance.post(
    `/guest/auth/reset-password`,
    email
  );
  return response;
};

export const loginWithFirebaseToken = async (token: string) => {
  const response = await axiosInstance.post(`/guest/auth/login-with-firebase`, {
    token: token,
  });
  return response;
};

export const registerWithFirebaseToken = async (data) => {
  const response = await axiosInstance.post(
    `/guest/auth/register/firebase-phone`,
    data
  );
  return response;
};

export const loginByVerifyOTP = async (data) => {
  const response = await axiosInstance.post(
    `/guest/auth/login/verify-otp`,
    data
  );
  return response;
};

export const registerByVerifyOTP = async (data) => {
  const response = await axiosInstance.post(
    `/guest/auth/register/verify-otp`,
    data
  );
  return response;
};

export const sendOTP = async (data) => {
  const response = await axiosInstance.post(`/guest/auth/send-otp`, data);
  return response;
};

export const checkAvailability = async (data) => {
  const response = await axiosInstance.post(
    `/guest/auth/check-availability`,
    data
  );
  return response;
};

export const register = async (data) => {
  const response = await axiosInstance.post(
    `/guest/auth/register-with-email`,
    data
  );
  return response;
};

export const getListCity = async (
  lat?: string,
  long?: string,
  name?: string
) => {
  const response = await axiosInstance.get(
    `/guest/rooms/cities?per_page=100&page=1${
      (lat ? `&lat=${lat}` : "") +
      (long ? `&long=${long}` : "") +
      (name ? `&name=${name}` : "")
    }`
  );
  return response;
};

export const getListHotels = async (
  version: string,
  cityId: string,
  checkin: string,
  checkout: string,
  hotelId?: string
) => {
  const response = await axiosInstance.get(`/guest/rooms/available-rooms`, {
    params: {
      cityId,
      checkinDate: checkin,
      checkoutDate: checkout,
      hotelId,
    },
    headers: {
      "x-channel-type": "PWA",
      "x-channel-version": version,
      "x-channel-name": "PWA",
    },
  });
  return response;
};

export const getDailyPrice = async (
  start_date: string,
  end_date: string,
  hotelId?: string
) => {
  const response = await axiosInstance.get(`/guest/rooms/dailyprices`, {
    params: { start_date, end_date, hotelId },
  });
  return response;
};

export const getRoomDetail = async (id: string) => {
  const response = await axiosInstance.get(`/guest/rooms/room/${id}`);
  return response;
};

export const getListGuestProfiles = async () => {
  const response = await axiosInstance.get(
    `/guest/guest-profiles?page=1&per_page=100`
  );
  return response;
};

export const calculateRoomOrder = async (
  data: {
    hotelId: String;
    checkinDate: String;
    checkoutDate: String;
    rooms: RoomType[];
    specialReq?: String;
    isCreateGuestProfile?: boolean;
    promoCode?: string[];
    subTotal?: number;
    totalPrice?: number;
    eligiblePointToUse?: number;
  },
  version
) => {
  const response = await axiosInstance.post<CalculateRoomDto>(
    `/guest/rooms/orders/calculate`,
    {
      ...data,
      estimedArrival:
        moment(new Date()).toObject().hours.toString() +
        "." +
        moment(new Date()).toObject().minutes.toString(),
    },
    {
      headers: {
        "x-channel-type": "PWA",
        "x-channel-name": "PWA",
        "x-channel-version": version,
      },
    }
  );
  return response;
};

export const createRoomOrder = async (data, version) => {
  const response = await axiosInstance.post(
    `/guest/rooms/orders`,
    {
      ...data,
      estimedArrival:
        moment(new Date()).toObject().hours.toString() +
        "." +
        moment(new Date()).toObject().minutes.toString(),
    },
    {
      headers: {
        "x-channel-type": "PWA",
        "x-channel-name": "PWA",
        "x-channel-version": version,
      },
    }
  );
  return response;
};

export const getDetailRoomOrder = async (order_id) => {
  const response = await axiosInstance.get(
    `/guest/rooms/orders/${order_id}/detail`
  );
  return response;
};

export const cancelRoomOrder = async (order_id) => {
  const response = await axiosInstance.post(
    `/guest/rooms/orders/${order_id}/status-cancel`
  );
  return response;
};

export const getHotelById = async (id: any) => {
  const response = await axiosInstance.get(`/guest/hotels/${id}`);
  return response;
};

export const calculateChangePayment = async (id: any) => {
  const response = await axiosInstance.post(
    `/guest/rooms/orders/${id}/calculate-change-payment`
  );
  return response;
};
export const changePayment = async (id: any, data: any) => {
  const response = await axiosInstance.post(
    `/guest/rooms/orders/${id}/change-payment`,
    data
  );
  return response;
};
